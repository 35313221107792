import { useEffect, useRef, useState } from 'react';
import VimeoPlayer, { Options as VimeoPlayerOptions } from '@vimeo/player';
import { css, styled } from '@neui/core';
import { AspectRatio, Box } from '@neui/layout';
import { SelfDescribingJson } from '@snowplow/browser-tracker';
import { useTracker } from '@utils/snowplowTracking';
import { Picture } from '@components/Picture/Picture';

export const CdsVimeoPlayerComponent = ({
  videoId,
  imageSrc,
  thumbnailAlt,
  context,
}: {
  videoId: number;
  imageSrc: string;
  thumbnailAlt: string;
  context: SelfDescribingJson;
}) => {
  const { trackLinkClick } = useTracker(CdsVimeoPlayerComponent.name);
  const [shouldStart, setShouldStart] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoContainer = useRef(null);

  useEffect(() => {
    const defaultOptions: VimeoPlayerOptions = {
      loop: false,
      autoplay: true,
      dnt: true,
      color: 'FFD700',
      quality: '720p',
      responsive: true,
      pip: false,
      title: true,
      playsinline: true,
      byline: true,
      transparent: false,
      texttrack: 'None',
      keyboard: true,
    };

    if (videoContainer?.current && shouldStart) {
      //put video player in  ref container
      const video = new VimeoPlayer(videoContainer.current, {
        ...defaultOptions,
        id: videoId,
      });
      video.on('play', (event) => {
        trackLinkClick?.('video_played', `${event.percent}`, [], '', [context]);
      });
      video.on('pause', (event) => {
        trackLinkClick?.('video_paused', `${event.percent}`, [], '', [context]);
      });
      video.on('ended', (event) => {
        trackLinkClick?.('video_ended', `${event.percent}`, [], '', [context]);
      });
      video.on('volumechange', async (event) => {
        const muted = await video.getMuted();
        trackLinkClick?.(
          `volumechange, muted: ${muted}`,
          `${event.volume}`,
          [],
          '',
          [context],
        );
      });
      video.on('fullscreenchange', (event) => {
        trackLinkClick?.('fullscreenchange', `${event.fullscreen}`, [], '', [
          context,
        ]);
      });
      video.on('enterpictureinpicture', (event) => {
        trackLinkClick?.('enterpictureinpicture', `${event}`, [], '', [
          context,
        ]);
      });
      video.on('playbackratechange', (event) => {
        trackLinkClick?.(
          'playbackratechange',
          `${event.playbackRate}`,
          [],
          '',
          [context],
        );
      });
      video.on('error', (event) => {
        trackLinkClick?.('error', `${event.message}`, [], '', [context]);
      });

      setIsPlaying(true);
    }
  }, [shouldStart, videoId]);

  return (
    <>
      <Container shouldDisplay={!isPlaying} aspectRatio={'16 / 9'}>
        <PosterImage
          sources={{
            '(min-width: 1024px)': {
              aspectRatio: 16 / 9,
              widths: [960, 1152],
              sizes: {
                '(min-width: 1195px)': '1200px',
                default: '720px',
              },
            },
            '(min-width: 360px)': {
              widths: [672],
              aspectRatio: 16 / 9,
              sizes: {
                default: '672px',
              },
            },
            default: {
              src: imageSrc,
              aspectRatio: 16 / 9,
              widths: [400],
              sizes: {
                default: '400px',
              },
            },
          }}
          alt=""
        />
        <PosterImageOverlay
          aria-label={thumbnailAlt}
          tabIndex={0}
          onClick={() => {
            setShouldStart(true);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === 'Space') {
              setShouldStart(true);
            }
          }}
        >
          <PlayButton
            aria-label="Video abspielen"
            tabIndex={-1}
            onClick={() => {
              setShouldStart(true);
            }}
          >
            <svg
              viewBox="0 0 72 72"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M36.5024 72.5807C56.3847 72.5807 72.5024 56.4629 72.5024 36.5807C72.5024 16.6984 56.3847 0.580688 36.5024 0.580688C16.6202 0.580688 0.502441 16.6984 0.502441 36.5807C0.502441 56.4629 16.6202 72.5807 36.5024 72.5807ZM48.5024 36.5807L28.5024 46.5807V26.5807L48.5024 36.5807Z"
                fill="white"
              />
            </svg>
          </PlayButton>
        </PosterImageOverlay>
      </Container>
      <VideoContainer
        aspectRatio={'16 / 9'}
        shouldDisplay={isPlaying}
        ref={videoContainer}
      ></VideoContainer>
    </>
  );
};

const shouldDisplayVariant = {
  shouldDisplay: {
    true: {
      display: 'block',
    },
    false: {
      display: 'none',
    },
  },
};

const Container = styled(AspectRatio, {
  position: 'relative',
  '&:focus-visible': {
    outline: '2px solid $text-standard',
    outlineOffset: 4,
    borderRadius: 8,
    overflow: 'hidden',
  },
  variants: {
    ...shouldDisplayVariant,
  },
});

const VideoContainer = styled(AspectRatio, {
  position: 'relative',
  variants: {
    ...shouldDisplayVariant,
  },
});

const PosterImage = styled(Picture, {
  '& img': {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
});

const PlayButton = styled('button', {
  cursor: 'pointer',
  height: '72px',
  border: 'none',
  backgroundColor: 'transparent',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '&:focus': {
    outline: 'none',
  },
  '& svg': {
    width: 40,
    height: 40,
  },
  '@sm': {
    '& svg': {
      width: 72,
      height: 72,
    },
  },
});

export const SharedOverlayStyles = css({});

export const SharedPlayButtonStyles = css({});

// Styling for poster / preview image overlay (basically a big button)
const PosterImageOverlay = styled(Box, {
  cursor: 'pointer',
  boxSizing: 'border-box',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  minHeight: 100,
  transition: 'background-color 0.4s ease',
  backgroundColor: 'rgba(0, 37, 48, 0.75)',
  '&:hover': {
    backgroundColor: 'rgba(0, 37, 48, 0.2)',
  },
  '&:focus-visible': {
    outline: '2px solid $text-standard',
    outlineOffset: 4,
    borderRadius: 8,
  },
});
