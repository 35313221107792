import { useMakeLink } from '@components/Link';
import { styled } from '@neui/core';
import { HStack, VStack } from '@neui/layout';
import { ReactElement } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { brands___apple, brands___google } from '@neui/styleguide-commerzbank';
import { DeviceTypes, MobileChecker } from '@utils/MobileChecker';
import { StyledActionButton } from '@components/Widgets/WidgetActionButton';
import { QrCodeLink } from '@components/Widgets/QrCodeWidget';
import { getWidgetDataByUrl } from '@utils/widgets/widgetData';
import { useTracker } from '@utils/snowplowTracking';
import { useRuntimeSettings } from '@utils/config';
import { GA4TrackLinkClick } from '@utils/tracking';

const googlePlayUrl = 'https://play.google.com';
const appStoreUrl = 'https://apps.apple.com';

const isGooglePlayUrl = (url: string) => url.includes(googlePlayUrl);
const isAppStoreUrl = (url: string) => url.includes(appStoreUrl);

const removeHlParam = (urlString: string): string => {
  const url = new URL(urlString);
  url.searchParams.delete('hl');
  return url.toString();
};

export const isMobileAppUrl = (url?: string): url is string =>
  url !== undefined && (isGooglePlayUrl(url) || isAppStoreUrl(url));

export type MobileAppLinkProps = {
  label: string;
  url: string;
};

export const MobileAppLink = ({
  label,
  url,
}: MobileAppLinkProps): ReactElement => {
  const makeLink = useMakeLink();
  const { trackLinkClick } = useTracker(MobileAppLink.name);
  const {
    tracking: {
      ga4: { enabled: enableGA4Tracking },
    },
  } = useRuntimeSettings();

  const mobileChecker = new MobileChecker();
  const mobileOs = mobileChecker.deviceType;

  const icon = isGooglePlayUrl(url) ? brands___google : brands___apple;

  if (
    (mobileOs === DeviceTypes.ANDROID && !isGooglePlayUrl(url)) ||
    (mobileOs === DeviceTypes.IOS && !isAppStoreUrl(url))
  ) {
    return <></>;
  }

  if (mobileOs === DeviceTypes.ANDROID || mobileOs === DeviceTypes.IOS) {
    return (
      <StyledActionButton
        label={label}
        href={url}
        look="secondary"
        icon={icon}
        variant="solid"
      >
        <HStack spacing={8} alignItems={'center'} css={{ width: '100%' }}>
          {label}
        </HStack>
      </StyledActionButton>
    );
  }

  const redirectUrl = makeLink({
    href: `/widget/?url=${encodeURIComponent(url)}`,
    absoluteUrl: true,
  });

  const logoImage = makeLink({
    href: isGooglePlayUrl(url)
      ? '/icons/google-logo.svg'
      : '/icons/apple-logo.svg',
    alwaysPrependBasePath: true,
  });

  const qrCodeLabel = isGooglePlayUrl(url)
    ? getWidgetDataByUrl(removeHlParam(url))?.description
    : getWidgetDataByUrl(url)?.description;

  return (
    <Container>
      <VStack spacing={12}>
        <div aria-label={`QR-Code für ${qrCodeLabel}`} role="img">
          <QRCode
            size={112}
            value={redirectUrl}
            fgColor={'#103D4B'}
            qrStyle="dots"
            logoPadding={1}
            logoImage={logoImage}
          />
        </div>
        {label && (
          <QrCodeLink
            href={redirectUrl}
            size={7}
            weight={'medium'}
            target="_blank"
            onClick={() => {
              trackLinkClick?.(url);
              enableGA4Tracking &&
                GA4TrackLinkClick(
                  url,
                  qrCodeLabel ?? '',
                  undefined,
                  'Article Page',
                );
            }}
          >
            {label}
          </QrCodeLink>
        )}
      </VStack>
    </Container>
  );
};

const Container = styled('div', {
  display: 'inline-block !important',
  width: 132,
  '& + &': {
    marginLeft: 32,
  },
});
